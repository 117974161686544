<template>
  <div class="viewDashBoard" v-cloak>
    <div class="container">
      <div
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <adminvue-projects-vue
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        ></adminvue-projects-vue>
        <projectcoordinators-projects-vue
           v-else-if="getActiveUserData.userRoles == 'projectcoordinators'"
        ></projectcoordinators-projects-vue>
        <sales-team-lead-vue-projects-vue
          v-else-if="(getActiveUserData.userRoles == 'salesteamlead') || (getActiveUserData.userRoles == 'salesmanager')"
        ></sales-team-lead-vue-projects-vue>
        <marketing-manager-projects-vue
          v-else-if="getActiveUserData.userRoles == 'marketingmanager'">
        </marketing-manager-projects-vue>
        <sales-person-vue-projects-vue
          v-else-if="getActiveUserData.userRoles == 'salesperson'"
        ></sales-person-vue-projects-vue>
        <client-vue-projects-vue
          v-else-if="getActiveUserData.userRoles == 'client'"
        ></client-vue-projects-vue>
        <default-vue-projects-vue v-else></default-vue-projects-vue>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import adminvue from "./components/project-view-childrens/admin-view";
import salesteamleadvue from "./components/project-view-childrens/salesteamlead-view";
import marketingmanagervue from "./components/project-view-childrens/marketing-manger-view";
import salespersonvue from "./components/project-view-childrens/salesperson-view";
import clientonvue from "./components/project-view-childrens/client-view";
import defaultvue from "./components/project-view-childrens/default-view";
import projectcoordinators from "./components/project-view-childrens/projectcoordinators-view.vue";

export default {
  components: {
    "adminvue-projects-vue": adminvue,
    "sales-team-lead-vue-projects-vue": salesteamleadvue,
    "marketing-manager-projects-vue": marketingmanagervue,
    "sales-person-vue-projects-vue": salespersonvue,
    "client-vue-projects-vue": clientonvue,
    "default-vue-projects-vue": defaultvue,
    "projectcoordinators-projects-vue": projectcoordinators,

  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {};
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
