var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-show"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"white-row"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{key:"2",staticClass:"project-show-details"},[(
              typeof _vm.project !== 'undefined' && Object.keys(_vm.project).length
            )?_c('div',{staticClass:"project-wrapper"},[_c('div',{staticClass:"main-title-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-9"},[_c('h4',[_vm._v(" Project #"+_vm._s(_vm.project.id)+" - "),_c('span',[_vm._v(" "+_vm._s(_vm.project.project_name)+" ")])])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"top-date text-right"},[_c('p',[_vm._v(_vm._s(_vm.project.created_at))])])])])]),_c('div',{staticClass:"project-nav-area"},[_c('nav',[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'projects.overview' },"tag":"li"}},[_vm._v(" Overview ")]),_c('router-link',{attrs:{"to":{ name: 'projects.files' },"tag":"li"}},[_vm._v(" Files ")]),_c('router-link',{attrs:{"to":{
                      path:
                        '/projects/show/' +
                        this.$route.params.project_id +
                        '/invoices'
                    },"tag":"li"}},[_vm._v(" Invoices ")]),_c('router-link',{attrs:{"to":{ name: 'projects.messages' },"tag":"li"}},[_vm._v(" Messages ")]),_c('router-link',{attrs:{"to":{ name: 'projects.others.settings' },"tag":"li"}},[_vm._v(" Tools ")]),_c('router-link',{staticStyle:{"float":"right","margin-top":"-8px"},attrs:{"to":{ path: '/projects/edit/' + this.$route.params.project_id  },"tag":"li"}},[_c('button',{staticClass:"btn btn-primary",staticStyle:{"font-size":"14px"}},[_vm._v(" Edit ")])])],1)])]),_c('div',{staticClass:"project-content-area"},[(_vm.displaySubChildren)?_c('div',{staticClass:"inner-Router"},[_c('router-view',{key:_vm.$route.fullPath})],1):_vm._e()])]):_c('div',{},[_vm._v(" No Record Found! ")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }